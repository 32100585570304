import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
  return(
    <footer className='jumbotron'>
      <Container>
      <a href='mailto:dmzinser@gmail.com' className='btn btn-primary'>Contact Me!</a>
      </Container>
    </footer>
  )
}

export default Footer;  