import GFLA from './Images/gfla.s.png';
import IET from './Images/ieattacos.s.png';
import EOG from './Images/eog.s.png';
import FF from './Images/ff.s.png';
import SCR from './Images/scrimble.s.png';

export const Data = [
  {
    id: 1,
    img: GFLA,
    header: 'Good Food LA Dashboard',
    text: 'This group hackathon project is a food system dashboard, designed and developed as a single-page React app employing Hooks and styled-components, for the Los Angeles Food Policy Council to provide access to, and visualization for, their aggregate data.',
    stack: '',
    link1: 'https://goodfoodla-hack-a-thon.firebaseapp.com/',
    link2: 'https://github.com/OladiranJ/GoodFoodLA-Hack-a-Thon',
    linkText1: 'View',
    linkText2: 'Code'
  },
  {
    id: 2,
    img: IET,
    header: 'iEatTacos',
    text: 'iEatTacos integrates Yelp Fusion API with Google Maps API to dynamically return the highest-rated taco shops based on the user’s current geo-location.  Deployment, database management and cloud functionality via Google Firebase. Please enable location services to view.',
    stack: '',
    link1: 'https://ga-capstone-c7083.firebaseapp.com/',
    link2: 'https://github.com/dmzinser/capstone-react-firebase',
    linkText1: 'View',
    linkText2: 'Code'
  },
  // {
  //   id: 3,
  //   img: EOG,
  //   header: 'Exchange-O-Gram',
  //   text: 'Exchange-O-Gram is a photo sharing web app that allows users to upload and edit their favorite photos.  This app was conceived with relational data models and employs a relational database to execute full CRUD functionality. App deployment via Heroku.',
  //   stack: '',
  //   link1: 'https://fierce-brook-99190-react.herokuapp.com/',
  //   link2: 'https://github.com/dmzinser/project3-react',
  //   linkText1: 'View',
  //   linkText2: 'Code'
  // },
  // {
  //   id: 4,
  //   img: FF,
  //   header: 'Furever Friend',
  //   text: 'Furever Friend is a group project MEN stack conceptualized as a community message board allowing animal shelters/caretakers to login and post available adoptees for adoption.  Adheres to REST best practices and features full CRUD functionality.',
  //   stack: '',
  //   link1: 'https://furever-friends.herokuapp.com/dog',
  //   link2: 'https://github.com/dmzinser/men-stack-project',
  //   linkText1: 'View',
  //   linkText2: 'Code'
  // },
  {
    id: 5,
    img: SCR,
    header: 'Scrimble',
    text: 'Scrimble is a single-player, timed, word-scrambling browser game themed to the Adult Swim original program "Tim & Eric Awesome Show, Great Job!"  Developed exclusively with vanilla JavaScript, the game logic randomizes word-selection and word-character order at each turn.',
    stack: '',
    link1: 'https://dmzinser.github.io/scrimble/',
    link2: 'https://github.com/dmzinser/scrimble',
    linkText1: 'View',
    linkText2: 'Code'
  }
];

export const Skills1 = ['JavaScript', 'HTML', 'CSS', 'Bootstrap', 'Semantic UI', 'Materialize'];

export const Skills2 = ['React', 'Express', 'Node', 'MongoDB', 'SQL', 'Python'];