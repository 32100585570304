import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHiking } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  return(
    <Navbar bg='light' expand='lg'>
      <div className='container'>
        <Navbar.Brand href='/'>
          <FontAwesomeIcon 
            icon={faHiking}
            size='md'
            className='mr-2'
            title='Hiker icon'
          /> 
          David M. Zinser
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link href='https://www.linkedin.com/in/david-zinser/' target='_blank'>LinkedIn</Nav.Link>
            <Nav.Link href='https://github.com/dmzinser' target='_blank'>GitHub</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Header;