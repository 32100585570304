import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';

const JumbotronHero = () => {
  return(
    <div className='jumbotron-container'>
      <Jumbotron>
        <div className='container hero'>
          <h1>Hey There!</h1>
          <p>
          My name is David and I'm a front end developer with a background in entrepreneurship and a passion for clean code.  An avid backpacker and outdoor-enthusiast, I endeavor to pack as light as my code and to never stop exploring new places, languages or technologies.  My current interests are serverless applications and test-driven development.
          </p>
          <p>
            <a href='/docs/david-zinser-resume.pdf' target='_blank' className='btn btn-primary'>Download Resume</a>
          </p>
          <div className='text-center'>
            <hr/>
            <p>
              JavaScript | HTML | SASS (CSS) | React | Express | Node.js | MongoDB | Mongoose | SQL | Python | Flask | Peewee | Firebase | Postman | Bootstrap | SemanticUI | Materialize | GitHub | SVN | Agile (JIRA) | Wordpress | Adobe XD | Adobe Photoshop | Adobe Illustrator
            </p>
          </div>
        </div>
      </Jumbotron>
    </div>
  )
}

export default JumbotronHero;