import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Data } from '../Data';

const Landing = () => {
  return(
    <div className='main-content'>
      <Container>
        <Row>
          {Data.map((d, i) => {
            return(
              <Col xs={12} md={6} lg={4} key={i}>
                <Card border='light' key={d.id}>
                  <Card.Body>
                    <Card.Img className='mb-2' variant='top' src={d.img} alt='Web App Screenshot'/>
                    <Card.Title as='h5'>{d.header}</Card.Title>
                      <Card.Text>
                        {d.text}
                      </Card.Text>
                      <Card.Link 
                        href={d.link1}
                        target='_blank'
                        variant='primary'>
                          <strong>{d.linkText1}</strong>
                      </Card.Link>
                      <Card.Link 
                        href={d.link2}
                        target='_blank'
                        variant='primary'>
                          <strong>{d.linkText2}</strong>
                      </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Landing;