import React from 'react';
import './App.css';
import { Route } from 'react-router-dom';
import Header from './Header';
import JumbotronHero from './JumbotronHero';
import Landing from './Landing';
import Footer from './Footer';

const App = () => {
  return (
    <div className='App'>
      <Header />
      <JumbotronHero />
      <Route exact path='/' component={Landing} />
      <Footer />
    </div>
  );
}

export default App;